import { configureStore } from '@reduxjs/toolkit';

import { cryptoApi } from '../services/cryptoApi';
import { cryptoNewsApi } from '../services/cryptoNewsApi';
import { localApi } from '../services/localApi';
import { dummyServive } from '../services/dummyService';

export default configureStore({
  reducer: {
    [cryptoApi.reducerPath]: cryptoApi.reducer,
    [cryptoNewsApi.reducerPath]: cryptoNewsApi.reducer,
    [localApi.reducerPath]: localApi.reducer,
    [dummyServive.reducerPath]: dummyServive.reducer,
  },
});
