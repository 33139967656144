import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const createRequest = (url, method = 'GET', body = null) => {
    const requestConfig = {
      url,
      headers: {
        'Content-Type': 'application/json', // Only necessary header remains
      },
    };
    
    if (method !== 'GET') {
      requestConfig.method = method;
      requestConfig.body = JSON.stringify(body);
    }
    
    return requestConfig;
  };
  
  export const dummyServive = createApi({
    reducerPath: 'dummyServive',
    baseQuery: fetchBaseQuery({
      baseUrl: process.env.NODE_ENV === 'production' ? 'https://zero-delta.io' : 'http://localhost:5555',
      credentials: 'include', // Important to include cookies in the request
    }),
    endpoints: (builder) => ({
      getTestAPI: builder.mutation({
        query: (body) => createRequest('/api/get-test-api', 'POST', body),
      }),
    }),
  });

  export const {
    useGetTestAPIMutation } = dummyServive;
  