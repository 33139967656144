import React, { useEffect, useState } from 'react';
import { Table, notification} from 'antd';
// import { GoogleLogin } from 'react-google-login';
import {  useGetPositionsQuery,useGetFrHistoryMutation } from '../services/localApi';
import './common.css';
import useTokenRefresh from '../services/useTokenRefresh';
import { showFrPopup } from './common';
import CheckAssetBalancesComponent from './CheckAssetBalancesComponent';
import CheckSymbolSpreadComponent from './CheckSymbolSpreadComponent';
import CheckFundingRateHistoryComponent from './CheckFundingRateHistoryComponent';
import PostTradeComponent from './PostTradeComponent';
import TradeInforComponent from './TradeInforComponent';
import AssetControlComponent from './AssetControlComponent';

const Positions = () => {
  useTokenRefresh();
  const [getFrHistory] = useGetFrHistoryMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const { data, isFetching, refetch, error } = useGetPositionsQuery(localStorage.getItem('access_token'));
  const [lastUpated, setLastUpdated] = useState('');
  const [BPositions, setBPositions] = useState([]);
  const [HPositions, setHPositions] = useState([]);
  
  const bColumns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        render: (text, record, index) => (
          <span 
              style={{ display: 'block', cursor: 'pointer', fontWeight: 'bold' }} 
              onClick={(e) => handleCellClick(e, record.symbol, 'B')} >
              {text}
          </span>
      ),
    },
    {
        title: 'B_size',
        dataIndex: 'B_size',
        className: 'binance-background',
        sorter: (a, b) => a.B_size - b.B_size,
        align: 'right',
    },
    {
        title: 'B_USD',
        dataIndex: 'B_USD',
        className: 'binance-background',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.B_USD - b.B_USD,
        align: 'right',
    },
    {
        title: 'B_PnL',
        dataIndex: 'B_PnL',
        className: 'binance-background',
        align: 'right',
        sorter: (a, b) => a.B_PnL - b.B_PnL,
    },
    {
        title: 'B_fr',
        dataIndex: 'B_fr',
        className: 'binance-background',
        sorter: (a, b) => a.B_fr - b.B_fr,
        render: (value) => {
          if (typeof value === 'number') {
              return (
                  <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
                      {value.toFixed(3)}
                  </span>
              );
          } else {
              return (
                  <span>
                      {value}
                  </span>
              );
          }
        },
        align: 'right',
    },
    {
        title: 'B_liq',
        dataIndex: 'B_liq',
        className: 'binance-background',
        align: 'right',
    },
    {
        title: 'out',
        dataIndex: 'out',
        className: 'binance-background',
        sorter: (a, b) => a.out - b.out,
        render: (value) => {
            if (typeof value === 'number') {
                return (
                    <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
                        {value.toFixed(3)}
                    </span>
                );
            } else {
                return (
                    <span>
                        {value}
                    </span>
                );
            }
        },
        align: 'right',
    },
  ];
  const hColumns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        render: (text, record, index) => (
          <span 
              style={{ cursor: 'pointer', fontWeight: 'bold'  }} 
              onClick={(e) => handleCellClick(e, record.symbol, 'H')}
          >
              {text}
          </span>
      ),
    },
    {
        title: 'H_size',
        dataIndex: 'H_size',
        className: 'bybit-background',
        sorter: (a, b) => a.H_size - b.H_size,
        align: 'right',
    },
    {
        title: 'H_USD',
        dataIndex: 'H_USD',
        className: 'bybit-background',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.H_USD - b.H_USD,
        align: 'right',
    },
    {
        title: 'H_PnL',
        dataIndex: 'H_PnL',
        className: 'bybit-background',
        align: 'right',
        sorter: (a, b) => a.H_PnL - b.H_PnL,
    },
    {
        title: 'H_fr',
        dataIndex: 'H_fr',
        className: 'bybit-background',
        sorter: (a, b) => a.H_fr - b.H_fr,
        // render: (value) => (
        //   <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
        //     {value.toFixed(3)}
        //   </span>
        // ),
        render: (value) => {
          if (typeof value === 'number') {
              return (
                  <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
                      {value.toFixed(3)}
                  </span>
              );
          } else {
              return (
                  <span>
                      {value}
                  </span>
              );
          }
        },
        align: 'right',
    },
    {
        title: 'H_liq',
        dataIndex: 'H_liq',
        className: 'bybit-background',
        align: 'right',
    },
    {
        title: 'out',
        dataIndex: 'out',
        className: 'bybit-background',
        sorter: (a, b) => a.out - b.out,
        render: (value) => {
            if (typeof value === 'number') {
                return (
                    <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
                        {value.toFixed(3)}
                    </span>
                );
            } else {
                return (
                    <span>
                        {value}
                    </span>
                );
            }
        },
        align: 'right',
    },
  ];

  const handleCellClick = async (e, symbol, exchange) => {
      // Prevent default behavior
      e.preventDefault();
      const res = await getFrHistory({ symbol: symbol, exchange: exchange, user: localStorage.getItem('access_token')}).unwrap();
      showFrPopup(e, symbol, exchange, res.response, res.summary); // Show result at popup
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60000); // 30 seconds

    if (initialLoad && !isFetching && data) {
      setInitialLoad(false);
    }
    if (data) {
      if (data.B) setBPositions(data.B);
      if (data.H) setHPositions(data.H);
      setLastUpdated(new Date().toLocaleString('en-GB'));
    }
    if (error) {
      notification.error({
        message: 'Cannot load positions ',
        description: `Login again !!`,
      });
    }

    return () => clearInterval(intervalId);
  }, [refetch, isFetching, data, initialLoad]);
  return (
    <div className="tables-container">
      <div style={{ marginRight: '20px', flex: '0.75', minWidth: '300px' }}>
        <PostTradeComponent />
        <AssetControlComponent />
        <CheckAssetBalancesComponent />
        <CheckSymbolSpreadComponent />
        <CheckFundingRateHistoryComponent />
        <TradeInforComponent />
      </div>

      {/* First table */}
      {/* uncomment below 3 lines to merge 2 positions table in 1 div */}
      {/* <div className="table-wrapper" style={{ flex: '2' }}> */}
        {/* <HistoricalSpreadComponent style={{ width: '100%' }} /> */}
        {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}> */}
          <div style={{ flex: '1' }}>
            <div className="status-grid">
              <div className="status-div">
                B positions
              </div>
              <div className="status-div">
                <div>Ratio :</div>
                <div style={{ paddingRight: '150px' }}>
                  {BPositions.ratio}
                  {' '}
                  %
                </div>
              </div>
              <div className="status-div">
                <div>Margin balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {BPositions.margin_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Wallet balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {BPositions.wallet_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>LONG :</div>
                <div style={{ paddingRight: '150px', color: 'green' }}>
                  {BPositions.long}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>SHORT :</div>
                <div style={{ paddingRight: '150px', color: 'red' }}>
                  {BPositions.short}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Cross margin :</div>
                <div style={{ paddingRight: '150px' }}>
                  {BPositions.cross_margin}
                </div>
              </div>
            </div>
            <Table
              columns={bColumns}
              dataSource={BPositions.positions}
              pagination={false}
              size="small"
                // onChange={onChange}
              style={{ marginRight: '20px' }}
            />
          </div>
          <div style={{ flex: '1' }}>
            <div className="status-grid">
              <div className="status-div">
                H positions
              </div>
              <div className="status-div">
                <div>Ratio :</div>
                <div style={{ paddingRight: '150px' }}>
                  {HPositions.ratio}
                  {' '}
                  %
                </div>
              </div>
              <div className="status-div">
                <div>Margin balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {HPositions.margin_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Wallet balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {HPositions.wallet_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>LONG :</div>
                <div style={{ paddingRight: '150px', color: 'green' }}>
                  {HPositions.long}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>SHORT :</div>
                <div style={{ paddingRight: '150px', color: 'red' }}>
                  {HPositions.short}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>updated :</div>
                <div style={{ paddingRight: '150px', color: 'blue' }}>
                  {lastUpated}
                  {' '}
                  $
                </div>
              </div>
            </div>
            <Table
              columns={hColumns}
              dataSource={HPositions.positions}
              pagination={false}
              size="small"
            />
          </div>
        </div>
      // </div>

    // </div>

  );
};

export default Positions;

