import React, { useState } from 'react';
import {Button, notification } from 'antd';
import { useRestartServerMutation } from '../services/localApi';
import { useGetTestAPIMutation } from '../services/dummyService';
import axios from 'axios';
const News = () => {
  
const [restartServer] = useRestartServerMutation();
const [getTestAPI, { data: serviceResponse, tempError }] = useGetTestAPIMutation();
const [axiosResponse, setAxiosResponse] = useState();
const SERVER = process.env.NODE_ENV === 'production' ? 'https://zero-delta.io/api/get-test-api' : 'http://localhost:5555/api/get-test-api';

const handleButtonCheckSymbol = async () => {
  try {
    // await getSymbol({ symbol: textBoxCheckSymbol}).unwrap();
    console.log('handleButtonCheckSymbol');
    await getTestAPI({ symbol: "BTCUSDT"}).unwrap();
    console.log("serviceResponse", serviceResponse);
  } catch (err) {
    console.error('Check symbol error details:', err);
    notification.error({
      message: 'Check symbol',
      description: `Error : ${JSON.stringify(err)}`,
    });
  }
};  


const handleButtonCheckSymbol2 = async () => {
  try {
    const temp = await axios.post('http://localhost:5555/api/get-test-api', { symbol: "BTCUSDT"}, {
      withCredentials: true, 
    });
    setAxiosResponse(temp);
    console.log("axiosResponse", axiosResponse);
  } catch (error) {
    // console.error('Error verifying user', error);
    
  }
};


const handleClearButton = () => {
  localStorage.setItem('isLoggedIn', 'false');
};

  const handleRestartButton = () => {
    
    try {
      restartServer({ user: localStorage.getItem('access_token')}).unwrap();
      notification.success({
        message: 'Success',
        description: `Restart success !`,
      });
    } catch (err) {
      notification.error({
        message: 'Error',
        description: `error : ${err?.data}`,
      });
    }
  };
  return (
    <div>
      <div>
        <Button className="normal-button" onClick={handleRestartButton}> Restart ws </Button>
      </div>
      <div>DO NOT CLICK
        <Button onClick={handleButtonCheckSymbol}> Call by service </Button>
        <Button onClick={handleButtonCheckSymbol2}> Call by Axios </Button>
        <Button onClick={handleClearButton}> Clear </Button>
      </div>
      {/* {serviceResponse && (
        <div>{serviceResponse}</div>
      )} */}
    </div>
  );
};

export default News;
