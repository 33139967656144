import React, { useEffect, useState } from 'react';
import { Table, Radio, notification, Input } from 'antd';
// import { GoogleLogin } from 'react-google-login';
import { useGetSpreadsQuery, useGetUsdtInterestQuery, useGetFrHistoryMutation } from '../services/localApi';
import Loader from './Loader';
import useTokenRefresh from '../services/useTokenRefresh';
import CheckAssetBalancesComponent from './CheckAssetBalancesComponent';
import CheckFundingRateHistoryComponent from './CheckFundingRateHistoryComponent';
import CheckSymbolSpreadComponent from './CheckSymbolSpreadComponent';
import PostTradeComponent from './PostTradeComponent';
import TradeInforComponent from './TradeInforComponent';
import HistoricalSpreadComponent from './HistoricalSpreadComponent';
import AssetControlComponent from './AssetControlComponent';
import CheckRESTSpreadComponent from './CheckRESTSpreadComponent';
import { showFrPopup } from './common';

const Spreads = () => {
  useTokenRefresh();
  const [usdtLoan, setUsdtLoan] = useState(0);
  const [usdtBorrow, setUsdtBorrow] = useState(0);
  const [lastUpated, setLastUpdated] = useState('');
  const [frThresholdRatioValue, setFrThresholdRatioValue] = useState('input');
  const [frThresholdTextValue, setFrThresholdTextValue] = useState(0.0);

  const [getFrHistory] = useGetFrHistoryMutation();
  const [sortOrder, setSortOrder] = useState(null);
  const [shortThreshold, setShortThreshold] = useState(() => {
    // Get saved value from localStorage or default to 0.05
    const savedValue = localStorage.getItem('highlightShortSpreadThreshold');
    return savedValue !== null ? parseFloat(savedValue) : 0.05;
  });
  const [longThreshold, setLongThreshold] = useState(() => {
    // Get saved value from localStorage or default to 0.05
    const savedValue = localStorage.getItem('highlightLongSpreadThreshold');
    return savedValue !== null ? parseFloat(savedValue) : -0.1;
  });
  const [shortFrThreshold, setShortFrThreshold] = useState(0.0); // Initialize threshold value
  const [initialLoad, setInitialLoad] = useState(true);
  const {data, isFetching, refetch, error} = useGetSpreadsQuery(localStorage.getItem('access_token'));
  const {
    data: usdtInterest, 
    refetch: refetchUsdtInterest } = useGetUsdtInterestQuery(localStorage.getItem('access_token'));


  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 30000); // 30 seconds

    if (initialLoad && !isFetching && data) {
      setInitialLoad(false);
    }

    if (data) {
      setLastUpdated(new Date().toLocaleString('en-GB'));
    }
    
    if (error) {
      notification.error({
        message: 'Cannot load spreads ',
        description: `Login again !!`,
      });
    }

    return () => clearInterval(intervalId);
  }, [refetch, isFetching, data, error, initialLoad]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const temp  = refetchUsdtInterest();
    }, 60000); // 30 seconds

    if (usdtInterest) {
      // console.log(usdtInterest);
      setUsdtBorrow(usdtInterest.borrow);
      setUsdtLoan(usdtInterest.loan);
    }
    return () => clearInterval(intervalId);
  }, [refetchUsdtInterest, usdtInterest]);


  const isFrAboveThreshold = (record, exchange, threshold) => {
    if (exchange === '') {
      return true;
    } 
    let fr_value;
    if (exchange === 'B') {
      fr_value =  parseFloat(record.B_fr);
    } else {
      fr_value =  parseFloat(record.H_fr);
    }

    if (!Number.isNaN(fr_value) && fr_value > threshold) {
      return true;
    } else {
      return false;
    }
  }

  const floatSorter = (a, b, key) => {
    const aValue = parseFloat(a[key]);
    const bValue = parseFloat(b[key]);
    const aIsFloat = !Number.isNaN(aValue);
    const bIsFloat = !Number.isNaN(bValue);
    if (aIsFloat && bIsFloat) {
      // return sortOrder === 'ascend' ? aValue - bValue : bValue - aValue;
      return aValue - bValue;
    } if (aIsFloat) {
      return sortOrder === 'ascend' ? 999 : -999;
    } if (bIsFloat) {
      return sortOrder === 'ascend' ? -999 : 999;
    }
      return 0; // both are strings or non-numbers
  };

  const renderShort = (value, record, index, exchange) => {
    const numericValue = parseFloat(value);
    const willHighlightShortSpread = !Number.isNaN(numericValue) && numericValue > shortThreshold && isFrAboveThreshold(record, exchange, shortFrThreshold);
    return (
      <span
        style={{
          display: 'block', // Make the span a block element
          color: willHighlightShortSpread ? 'green' : 'black',
          fontWeight: willHighlightShortSpread ? 'bold' : 'normal',
          cursor: 'pointer'
        }}
        onClick={(e) => handleCellClick(e, record.symbol, exchange)}
      >
        {value}
      </span>
    );
  };

  const renderLong =  (value, record, index, exchange) => {
    const numericValue = parseFloat(value);
    const isLowerLongThreshold = !Number.isNaN(numericValue) && numericValue < longThreshold;
    return (
      <span
        style={{
          display: 'block', // Make the span a block element
          color: isLowerLongThreshold ? 'red' : 'black',
          fontWeight: isLowerLongThreshold ? 'bold' : 'normal',
          cursor: 'pointer',
        }}
        onClick={(e) => handleCellClick(e, record.symbol, exchange)}
      >
        {value}
      </span>
    );
  };

  const handleShortThresholdChange = (e) => {
    const newValue = parseFloat(e.target.value);
    localStorage.setItem('highlightShortSpreadThreshold', newValue);
    setShortThreshold(newValue);
  };
  const handleLongThresholdChange = (e) => {
    const newValue = parseFloat(e.target.value);
    localStorage.setItem('highlightLongSpreadThreshold', newValue);
    setLongThreshold(parseFloat(newValue));
  };

  const spreadsColumns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
      },
      {
        title: 'AB_short',
        dataIndex: 'AB_short',
        sorter: (a, b) => floatSorter(a, b, 'AB_short'),
        render: (text, record, index) => renderShort(text, record, index, 'B'),
        align:'right',
      },
      {
        title: 'AB_long',
        dataIndex: 'AB_long',
        sorter: (a, b) => floatSorter(a, b, 'AB_long'),
        render: (text, record, index) => renderLong(text, record, index, 'B'),
        align:'right',
      },
      {
        title: 'AG_short',
        dataIndex: 'AG_short',
        sorter: (a, b) => floatSorter(a, b, 'AG_short'),
        render: (text, record, index) => renderShort(text, record, index, ''),
        align:'right',
      },
      {
        title: 'AG_long',
        dataIndex: 'AG_long',
        sorter: (a, b) => floatSorter(a, b, 'AG_long'),
        render: (text, record, index) => renderLong(text, record, index, ''),
        align:'right',
      },
      {
        title: 'AH_short',
        dataIndex: 'AH_short',
        sorter: (a, b) => floatSorter(a, b, 'AH_short'),
        // defaultSortOrder: 'descend',
        render: (text, record, index) => renderShort(text, record, index, 'H'),
        align:'right',
      },
      {
        title: 'AH_long',
        dataIndex: 'AH_long',
        sorter: (a, b) => floatSorter(a, b, 'AH_long'),
        render: (text, record, index) => renderLong(text, record, index, 'H'),
        align:'right',
      },
      {
        title: 'BG_short',
        dataIndex: 'BG_short',
        sorter: (a, b) => floatSorter(a, b, 'BG_short'),
        render: (text, record, index) => renderShort(text, record, index, 'B'),
        align:'right',
      },
      {
        title: 'BG_long',
        dataIndex: 'BG_long',
        sorter: (a, b) => floatSorter(a, b, 'BG_long'),
        render: (text, record, index) => renderLong(text, record, index, 'B'),
        align:'right',
      },
      {
        title: 'BH_short',
        dataIndex: 'BH_short',
        sorter: (a, b) => floatSorter(a, b, 'BH_short'),
        render: (text, record, index) => renderShort(text, record, index, 'BH'),
        align:'right',
      },
      {
        title: 'BH_long',
        dataIndex: 'BH_long',
        sorter: (a, b) => floatSorter(a, b, 'BH_long'),
        render: (text, record, index) => renderLong(text, record, index, 'BH'),
        align:'right',
      },
      {
        title: 'GH_short',
        dataIndex: 'GH_short',
        sorter: (a, b) => floatSorter(a, b, 'GH_short'),
        render: (text, record, index) => renderShort(text, record, index, 'H'),
        align:'right',
      },
      {
        title: 'GH_long',
        dataIndex: 'GH_long',
        sorter: (a, b) => floatSorter(a, b, 'GH_long'),
        render: (text, record, index) => renderLong(text, record, index, 'H'),
        align:'right',
      },
  ];
  
  const handleCellClick = async (e, symbol, exchange) => {
    // Prevent default behavior
    if (exchange !== '') {
      e.preventDefault();
      const res = await getFrHistory({ symbol: symbol, exchange: exchange, user: localStorage.getItem('access_token')}).unwrap();
      showFrPopup(e, symbol, exchange, res.response, res.summary); // Show result at popup
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder(sorter.order);
  };

  const handleChangeFrThresholdRatio = (value)  => {
    setFrThresholdRatioValue(value);
    if (value === 'borrow') {
      setShortFrThreshold(usdtBorrow / 365 / 3);
    } else if (value === 'loan') {
      setShortFrThreshold(usdtLoan / 365 / 3);
    } else {
      setShortFrThreshold(frThresholdTextValue);
    }
  }

  const handleChangeFrThresholdTextValue = (value)  => {
    setFrThresholdTextValue(value);
    setShortFrThreshold(value);
  }

  // const [visibleColumns, setVisibleColumns] = useState(spreadsColumns.map(col => col.dataIndex));

  // const handleCheckboxChange = (dataIndex) => {
  //   setVisibleColumns(prev => 
  //     prev.includes(dataIndex) 
  //     ? prev.filter(col => col !== dataIndex) 
  //     : [...prev, dataIndex]
  //   );
  // };
  // const filteredColumns = spreadsColumns.filter(col => visibleColumns.includes(col.dataIndex));


  return (
    <div className="tables-container">
      <div style={{ marginRight: '20px', flex: '0.75', minWidth: '300px' }}>
        <PostTradeComponent />
        <AssetControlComponent />
        <CheckAssetBalancesComponent />
        <CheckSymbolSpreadComponent />
        <CheckFundingRateHistoryComponent />
      </div>

      <div style={{ flex: '2' }}>
        <CheckRESTSpreadComponent/>
        <HistoricalSpreadComponent />
        <TradeInforComponent />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px' }}>
          <div style={{ width: '100px' }}>Spread short</div>
          <input style={{ marginLeft: '20px', width: '70px' }} type="number" value={shortThreshold} onChange={handleShortThresholdChange} />
          <div style={{ marginLeft: '20px' }}>FR higher than  </div> 
          <Radio.Group
            value={frThresholdRatioValue}
            onChange={(e) => { handleChangeFrThresholdRatio(e.target.value); }}
            style={{ marginLeft: '10px' }}
          >
            <Radio value="input">
              <Input
              type="number" 
                value={frThresholdTextValue}
                style={{ width: '80px' }}
                onChange={(e) => handleChangeFrThresholdTextValue(e.target.value)}
                disabled={frThresholdRatioValue !== 'input'}
              />
            </Radio>
            <Radio value="borrow">Borrow {usdtBorrow}%  -- {usdtLoan}%</Radio>
          </Radio.Group>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
          <div style={{ width: '100px' }}>Spread long</div>
          <input style={{ marginLeft: '20px', width: '70px' }} type="number" value={longThreshold} onChange={handleLongThresholdChange} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', color: 'blue', marginTop: '5px' }}>
          {'Last updated :'}
          {lastUpated}
        </div>

        {initialLoad && isFetching && <Loader />}
        {(
          // <div style={{ display: 'flex', flex: '1'}}, flexWrap: 'wrap', backgroundColor: '#001529 !important', color: '#ffffff !important' }}>
          <div >
          {/* <div style={{ marginTop: '10px' }}>
            {spreadsColumns.map(col => (
              <Checkbox 
                key={col.dataIndex} 
                checked={visibleColumns.includes(col.dataIndex)} 
                onChange={() => handleCheckboxChange(col.dataIndex)}
              >
                {col.title}
              </Checkbox>
            ))}
          </div> */}
            <div style={{ marginTop: '20px' }}>
              <Table
                columns={spreadsColumns}
                dataSource={data}
                pagination={false}
                size="small"
                onChange={handleTableChange}
                // onCell={(record, rowIndex, columnIndex) => ({
                //     onClick: (e) => {
                //         const columnName = spreadsColumns[columnIndex].title; // Get the column name
                //         const symbol = record.symbol; // Get the symbol from the record
                //         handleCellClick(e, symbol, columnName); // Call your handleClickButton function
                //     },
                // })} 

              />
            </div>
          </div>
          )}

      </div>
    </div>
  );
};

export default Spreads;
