import React, { useEffect, useState } from 'react';
import useTokenRefresh from '../services/useTokenRefresh';
import { Table, Button, notification, Radio, Spin, Divider,Tooltip   } from 'antd';
import { useGetAllAssetsQuery, usePostAssetActionMutation, useGetIsolatedWalletsQuery, useDisableIsolatedWalletsMutation, useGetCrossWalletQuery } from '../services/localApi';
import { RedoOutlined } from '@ant-design/icons';
import AssetControlComponent from './AssetControlComponent';

import './common.css';

const Assets = () => {
  useTokenRefresh();
  const [sortOrder] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [postAssetAction] = usePostAssetActionMutation();
  const { data, isFetching, refetch, error} = useGetAllAssetsQuery(localStorage.getItem('access_token'));
  const { data: isolatedWallet, 
    isFetching: isFetchingIsolated, 
    refetch: refetchIsolatedWallets, 
    error: error2  } = useGetIsolatedWalletsQuery(localStorage.getItem('access_token'));
  const {  data: crossWallet, 
    isFetching: isFetchingCross, 
    refetch: refetchCrossWallet, 
    error: error3  } = useGetCrossWalletQuery(localStorage.getItem('access_token'));

  const [disableIsolatedWallets] = useDisableIsolatedWalletsMutation();
  
  const [setIsVisible] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 45000); // 30 seconds

    if (initialLoad && !isFetching && data) {
      setInitialLoad(false);
    }
    if (error) {
      notification.error({
        message: 'Refresh data',
        description: 'Error !! Login again.',
      });
    }
    if (data) {
      setLastUpdated(new Date().toLocaleString('en-GB'));
    }

    return () => clearInterval(intervalId);
  }, [refetch, isFetching, data, error, initialLoad]);

  const floatSorter = (a, b, key) => {
    const aValue = parseFloat(a[key]);
    const bValue = parseFloat(b[key]);
    const aIsFloat = !Number.isNaN(aValue);
    const bIsFloat = !Number.isNaN(bValue);
    if (aIsFloat && bIsFloat) {
      // return sortOrder === 'ascend' ? aValue - bValue : bValue - aValue;
      return aValue - bValue;
    } if (aIsFloat) {
      return sortOrder === 'ascend' ? 999 : -999;
    } if (bIsFloat) {
      return sortOrder === 'ascend' ? -999 : 999;
    }
      return 0; // both are strings or non-numbers
  };

  const assetColumns = [
    {
        title: 'asset',
        dataIndex: 'asset',
        sorter: (a, b) => floatSorter(a, b, 'asset'),
    },
    {
        title: 'SPOT',
        dataIndex: 'spot',
        sorter: (a, b) => floatSorter(a, b, 'spot'),
        align: 'right',
        render: (text) => text.toFixed(2), 
    },
    {
        title: 'ISO',
        dataIndex: 'iso',
        sorter: (a, b) => floatSorter(a, b, 'iso'),
        align: 'right',
        render: (text) => text.toFixed(2),
    },
    {
        title: 'CROSS',
        dataIndex: 'cross',
        sorter: (a, b) => floatSorter(a, b, 'cross'),
        align: 'right',
        render: (text) => text.toFixed(2),
    },
    {
        title: 'PERP',
        dataIndex: 'perp',
        sorter: (a, b) => floatSorter(a, b, 'perp'),
        align: 'right',
        render: (text) => text.toFixed(2),
    },
    {
        title: 'EARN',
        dataIndex: 'earn',
        sorter: (a, b) => floatSorter(a, b, 'earn'),
        align: 'right',
        render: (text) => text.toFixed(2),
    },
    {
        title: 'VALUE',
        dataIndex: 'value',
        sorter: (a, b) => floatSorter(a, b, 'value'),
        align: 'right',
        render: (text) => text.toFixed(0),
    },
    {
        title: 'APY',
        dataIndex: 'apy',
        sorter: (a, b) => floatSorter(a, b, 'apy'),
        align: 'right',
        render: (text) => text.toFixed(2),
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        render: (text, record) => (
            <>
                {/* <Button type="primary" shape="round" onClick={() => handleAssetActionEarn(record)}>Earn</Button>
                <Button type="primary" shape="round" onClick={() => handleAssetActionRedeem(record)}>Redeem</Button>
                <Button type="primary" shape="round" onClick={() => handleAssetActionSendToBorrow(record)}>Send_to_borrow</Button> */}
              <Radio.Group >
                <Radio.Button onClick={() => handleAssetActionEarn(record)}>Earn</Radio.Button>
                <Radio.Button onClick={() => handleAssetActionRedeem(record)}>Redeem</Radio.Button>
                
                <Tooltip title="Transfer earn+spot to isolated, borrow USDT"> 
                  <Radio.Button onClick={() => handleAssetActionSendToBorrow(record)}>Send_to_borrow</Radio.Button>
                </Tooltip>
              </Radio.Group>
            </>
        ),
    },
  ];

  const crossColumns = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      sorter: (a, b) => floatSorter(a, b, 'asset'),
    },
    {
      title: 'Free',
      dataIndex: 'free',
      sorter: (a, b) => floatSorter(a, b, 'free'),
      align: 'right',
      render: (text) => text.toFixed(2),
    },
    {
      title: 'Borrow',
      dataIndex: 'borrowed',
      sorter: (a, b) => floatSorter(a, b, 'borrowed'),
      align: 'right',
      render: (text) => text.toFixed(2),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
          <>
            <Radio.Group >
              <Radio.Button disabled onClick={() => handleIsolatedActionBorrowOut(record)}>Repay</Radio.Button>
              {/* <Radio.Button onClick={() => handleIsolatedActionSendToRepay(record)}>Send-to-repay</Radio.Button> */}
            </Radio.Group>
          </>
      ),
    },
  ]

  const isolatedColumns = [
    {
      title: 'wallet',
      dataIndex: 'symbol',
      sorter: (a, b) => floatSorter(a, b, 'asset'),
    },
    {
      title: 'Base',
      dataIndex: 'base',
      sorter: (a, b) => floatSorter(a, b, 'base'),
      align: 'right',
      render: (text) => text.toFixed(2),
    },
    {
      title: 'Borrow',
      dataIndex: 'baseBorrowed',
      sorter: (a, b) => floatSorter(a, b, 'baseBorrowed'),
      align: 'right',
      render: (text) => text.toFixed(2),
    },
    {
      title: 'Quote',
      dataIndex: 'quote',
      sorter: (a, b) => floatSorter(a, b, 'quote'),
      align: 'right',
      render: (text) => text.toFixed(2),
    },
    {
      title: 'Borrow',
      dataIndex: 'quoteBorrowed',
      sorter: (a, b) => floatSorter(a, b, 'quoteBorrowed'),
      align: 'right',
      render: (text) => text.toFixed(2),
    },
    {
      title: 'Ratio',
      dataIndex: 'marginLevel',
      sorter: (a, b) => floatSorter(a, b, 'marginLevel'),
      align: 'right',
      render: (text) => text.toFixed(2),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
          <>
            <Radio.Group >
              <Tooltip title="Borrow USDT if ratio > 2, no redeem and transfer"> 
                <Radio.Button onClick={() => handleIsolatedActionBorrowOut(record)}>Borrow-out</Radio.Button> 
              </Tooltip>
              
              <Tooltip title="Use asset in isolated and spot to repay. Do NOT redeem"> 
                <Radio.Button onClick={() => handleIsolatedActionSendToRepay(record)}>Send-to-repay</Radio.Button>
              </Tooltip>
            </Radio.Group>
          </>
      ),
  },
  ]
  const doAction = async (selectedAction, fromWallet, toWallet, coin, collaAsset, amount) => {

    try {
      console.log(selectedAction, fromWallet, toWallet, coin, amount);
      const response = await postAssetAction({ user: localStorage.getItem('access_token'),
        selectedAction,
        fromWallet: fromWallet,
        toWallet: toWallet,
        coin: coin,
        collaAsset: collaAsset,
        amount }).unwrap();
      console.log(response);
      notification.success({
        message: 'Success',
        description: `${response}`,
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: `${selectedAction} error : ${JSON.stringify(err)}`,
      });
    }
  };
  const handleIsolatedWithdrawDust = async () => {
    await doAction('withdraw-dust', '', 'SPOT', '', '', ''); // set amount = 0 to do not transfer
  };
  const handleIsolatedActionBorrowOut = async (record) => {
    let temp = record.symbol;
    await doAction('send-to-borrow', record.symbol, record.symbol, 'USDT', temp.replace("USDT", ""), '0'); // set amount = 0 to do not transfer
  };
  const handleIsolatedActionSendToRepay = async (record) => {
    await doAction('send-to-repay', record.symbol, record.symbol, '', '', '');
  }; 
  const handleAssetActionEarn = async (record) => {
    setIsVisible(false);
    await doAction('subcribe', '', '', record.asset, '', '');
  };
  const handleAssetActionRedeem = async (record) => {
    setIsVisible(false);
    await doAction('redeem', '', '', record.asset, '', '');
  };
  const handleAssetActionSendToBorrow = async (record) => {
    setIsVisible(false);
    if (record.asset === 'USDT') {
      notification.error({
        message: 'Error',
        description: 'USDT not support send-to-borrow yet',
      });
    } else {
      await doAction('send-to-borrow', record.asset + 'USDT', record.asset + 'USDT',  'USDT', record.asset, 'all');
    }
  };

  const handleRefreshButton = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  const handleGetIsolatedWalletsButton = async () => {
    await refetchIsolatedWallets();
  };

  const handleGetCrossWalletButton = async () => {
    await refetchCrossWallet();
  };

  const handleIsolatedDisableButton = async () => {
    try {
      await disableIsolatedWallets({ user: localStorage.getItem('access_token')}).unwrap();
      notification.success({message: 'Success', description: 'Disable all un-use isolated wallet'});
    } catch (err) {
      console.log(err);
      notification.error({message: 'Error', description: `${JSON.stringify(err)}`,});
    }
  };

  return (
    <div className="tables-container">
    
    <div style={{flex: '0.75'}}>
        <Divider variant="dashed" style={{  borderColor: '#001529' }} orientation="left"> SINGLE ACTIONS  
        </Divider>
        <AssetControlComponent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}/>
        <Divider variant="dashed" style={{  borderColor: '#001529' }} orientation="left"> ISOLATED 
          <Button type="primary" ghost shape="round"  style={{marginLeft: '25px'}} onClick={() => handleGetIsolatedWalletsButton()} icon={<RedoOutlined />} > Refresh </Button>
          {(loading || isFetchingIsolated) && (
              <Spin style={{ marginLeft: '10px' }} />
            )}
        </Divider>
        <Button className="normal-button" onClick={() => handleIsolatedDisableButton()} > Disable </Button>
        <Button className="normal-button" style={{width:'120px'}}  onClick={() => handleIsolatedWithdrawDust()} > Withdraw dust </Button>
        {isolatedWallet
              && (
              <div style={{"marginTop" : "10px"}}>
                <div style={{fontWeight: 'bold', fontSize: '15px'}}>Enable wallets : {isolatedWallet.length} wallets </div>

                <Table
                  columns={isolatedColumns}
                  dataSource={isolatedWallet}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />
              </div>
        )}
        <Divider variant="dashed" style={{  borderColor: '#001529' }} orientation="left"> CROSS 
          <Button type="primary" ghost shape="round"  style={{marginLeft: '25px'}} onClick={() => handleGetCrossWalletButton()} icon={<RedoOutlined />} > Refresh </Button>
          {(loading || isFetchingCross) && (
              <Spin style={{ marginLeft: '10px' }} />
            )}
        </Divider>
        
        {crossWallet
              && (
              <div style={{"marginTop" : "10px"}}>
                List all assets more than 1000$
                <Table
                  columns={crossColumns}
                  dataSource={crossWallet}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />
              </div>
        )}
      </div>
      <div style={{flex: '1', marginLeft:"20px"}}>
        {/* <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}> */}
        List all assets more than 1000 $
        <Button type="primary" ghost shape="round" onClick={() => handleRefreshButton()} style={{'marginLeft': '20px'}} icon={<RedoOutlined />}> Refresh </Button>
        {(loading || isFetching) && (
            <Spin style={{ marginLeft: '10px' }} />
          )}
        {/* </div> */}
        {data
              && (
              <div>

                <Table
                  columns={assetColumns}
                  dataSource={data}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />
              </div>
        )}
      </div>
    </div>
  );
};

export default Assets;

