// CheckAssetBalancesComponent.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Menu, Dropdown } from 'antd';
import { useGetSymbolSpreadMutation } from '../services/localApi';

import './common.css';

const CheckRESTSpreadComponent = () => {
    
    const [selectedPair, setSelectedPair] = useState('AH');
    const [inputWatchSymbol, setInputWatchSymbol] = useState('');
    const [watchSymbols, setWatchSymbols] = useState([]);
    const [watchSymbolSpreads, setWatchSymbolSpreads] = useState([]);

    const [getWatchSymbolSpreads] = useGetSymbolSpreadMutation();

    const pairMenu = (
        <Menu onClick={(e) => setSelectedPair(e.key)}>
        <Menu.Item key="AB">AB</Menu.Item>
        <Menu.Item key="AG">AG</Menu.Item>
        <Menu.Item key="AH">AH</Menu.Item>
        <Menu.Item key="BG">BG</Menu.Item>
        <Menu.Item key="BH">BH</Menu.Item>
        <Menu.Item key="GH">GH</Menu.Item>
        </Menu>
    );
    const handleAddWatchSymbol = async () => {
        const tmp = inputWatchSymbol.split(',');
        let currentWatchList = watchSymbols;
        for (let i = 0; i < tmp.length; i++) {
        let key = tmp[i].toUpperCase().replace(" ", "");
        if ((key.length < 5) || (key.length > 5 && key.slice(-4) !== 'USDT')) {
            key = key + 'USDT_' + selectedPair
        } else {
            key = key + '_' + selectedPair
        }
        if (!currentWatchList.includes(key)) {
            currentWatchList = currentWatchList.concat(key);
        }
        }
        if (currentWatchList !== watchSymbols) {
        setWatchSymbols(currentWatchList);
        }
        
    };
    const handleClearWatchSymbol = (e) => {
        setWatchSymbolSpreads([]);
        setWatchSymbols([]);
    };

    const handleDeleteWatchSymbol = (sValue, pairValue) => {
        let key = sValue + '_' + pairValue;
        let temp = watchSymbols;
        if (temp.includes(key)) {
        temp.splice(temp.indexOf(key), 1);
        setWatchSymbols(temp);
        }
    };
    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (watchSymbols.length > 0) {
            const response = await getWatchSymbolSpreads({ user: localStorage.getItem('access_token'), keys: watchSymbols});
            if (response?.data) {
                setWatchSymbolSpreads(response.data);
            }
            } else {
            setWatchSymbolSpreads([]);
            }
        }, 5000); // 30 seconds
        return () => clearInterval(intervalId);
    }, [watchSymbols]);


    const watchSpreadColumns = [
    {
        title: 'Symbol',
        dataIndex: 's',
    },
    {
        title: 'Pair',
        dataIndex: 'pair',
    },
    {
        title: 'SHORT',
        dataIndex: 'short',
    },
    {
        title: 'LONG',
        dataIndex: 'long',
    },
    {
        title: 'Action',
        render: (text, record) => (
            <Button danger onClick={() => handleDeleteWatchSymbol(record.s, record.pair)}> Del </Button>
        ),
    },
    ];

    return (
       <div >
        <div>
          <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}>REST spread : </div>
            <Dropdown style={{ margin: '10px' }} overlay={pairMenu}>
              <Button>
                {selectedPair}
              </Button>
            </Dropdown>
            
          <input style={{ marginLeft: '10px' }} type="text" value={inputWatchSymbol} onChange={(e) => setInputWatchSymbol(e.target.value)} />
          <Button className="normal-button" style={{ marginLeft: '10px' }} type="primary" onClick={handleAddWatchSymbol}> Add </Button>
          <Button danger style={{ marginLeft: '10px' }} onClick={handleClearWatchSymbol}> Clear </Button>
          </div>
        </div>
        {watchSymbolSpreads.length > 0 && (
                <Table 
                    columns={watchSpreadColumns}
                    dataSource={watchSymbolSpreads}
                    size="small"
                    style={{display: 'flex', flex: '1' }}
                    pagination={false}
                />
            )}

      </div>
    );
  };

export default CheckRESTSpreadComponent;
