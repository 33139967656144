import React from 'react';
import { Modal, Input, Radio } from 'antd';

const TradeModal = ({ visible, title, onOk, onCancel, symbol, setSymbol, amountUSD, setAmountUSD, bidask, setBidask, isolatedVisible, isolated, onChangeIsolated }) => {
    return (
        <Modal
            title={title}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
                <div style={{ width: '120px' }}> Symbol : </div>
                <Input style={{ margin: '5px' }} value={symbol} onChange={(e) => setSymbol(e.target.value)} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
                <div style={{ width: '160px' }}> Amount USD : </div>
                <Input style={{ margin: '5px' }} type="number" placeholder="leave blank to run out current position" value={amountUSD} onChange={(e) => setAmountUSD(e.target.value)} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
                <div style={{ width: '120px' }}> Bid ask : </div>
                <Input style={{ margin: '5px' }} type="number" value={bidask} onChange={(e) => setBidask(e.target.value)} />
            </div>
            {isolatedVisible && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
                    <div style={{ width: '120px' }}> Isolated : </div>
                    <Radio.Group onChange={onChangeIsolated} value={isolated}>
                        <Radio value>True</Radio>
                        <Radio value={false}>False</Radio>
                        <Radio value={null}>None</Radio>
                    </Radio.Group>
                </div>
            )}
        </Modal>
    );
};

export default TradeModal;