// src/PrivateRoute.js
import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStateContext } from './app/ContextProvider';
import { useAuth } from './AuthContext'; // Import the custom hook


const PrivateRoute = ({ component: Component, ...rest }) => {
    // // const { isLoggedIn } = useStateContext();
    // const { isLoggedIn } = useAuth(); // Use context here
    // console.log('PrivateRoute', isLoggedIn);

    const { isLoggedIn } = useAuth();
    const isLoggedInFromLocalStorage = localStorage.getItem('isLoggedIn') === 'true'; // Check localStorage
    const isAuthenticated = isLoggedIn || isLoggedInFromLocalStorage; // Combine both checks
    return (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(props) => ((isAuthenticated) ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...props} />
            ) : (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            ))}
      />
    );
};

export default PrivateRoute;
